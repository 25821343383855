import React from "react";
import * as Tooltip from "@radix-ui/react-tooltip";
import { Link } from "react-router-dom";

const SidebarLink = ({ to, label, icon, isOpen }) => {
  return (
    <Link
      to={to}
      className="flex items-center p-2  text-gray-800 dark:text-gray-300"
    >
      <Tooltip.Provider>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <div className="flex justify-center items-center h-8 w-8 bg-white dark:bg-gray-900 rounded-lg shadow-lg dark:hover:text-white dark:hover:bg-blue-600 dark:hover:bg-opacity-30">
              {icon}
            </div>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="bg-white dark:bg-gray-900 opacity-95 rounded-lg p-2 shadow-xl dark:text-white"
              side="right"
              sideOffset={5}
            >
              {label}
              <Tooltip.Arrow className="fill-white dark:fill-black" />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>

      {isOpen && <span className="ml-2  text-sm">{label}</span>}
    </Link>
  );
};

export default SidebarLink;
