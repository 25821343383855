import { DateTime } from "luxon"

export const formatDate = d => {
  let result = null

  if (!!d && d !== "") {
    if (!d.includes("T") && !d.includes("Z")) {
      result = DateTime.fromSQL(d).toISODate()
    } else {
      result = DateTime.fromISO(d).toISODate()
    }
  }

  return result
}

export const formatDateNoTime = d => {
  if (!!d) {
    return DateTime.fromSQL(d).toFormat("yyyy-MM-dd")
  }
  return null
}
